import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import index from "../views/Index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: index,
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/sms",
    name: "sms",
    component: () => import("../views/sms.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/serviceOpen",
    name: "serviceOpen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/serviceOpen.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/igOpen",
    name: "igOpen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/igOpen.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/businessCard",
    name: "businessCard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/businessCard.vue"),
    meta: {
      hidden: true,
      title: "名片",
    },
  },
  {
    path: "/qrCode",
    name: "qrCode",
    component: () => import("../views/qrCode.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("../views/notice.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/serviceOpen",
    name: "serviceOpen",
    component: () => import("../views/serviceOpen.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  // 申请试用
  {
    path: "/trial",
    name: "trial",
    component: () => import("../views/trial/index.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/richText",
    name: "richText",
    component: () => import("../views/trial/richText.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/trialStatus",
    name: "trialStatus",
    component: () => import("../views/trial/trialStatus.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
  {
    path: "/h5Photo",
    name: "h5Photo",
    component: () => import("../views/h5Photo.vue"),
    meta: {
      hidden: true,
      title: "跳转中",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// hash 模式
// const router = createRouter({
// 	history: createWebHashHistory(),
// 	routes,
// });

export default router;
