import axios from "axios";
import {
  Toast
} from "vant";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 60 * 1000, // request timeout
});

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 1001) {
      Toast(res.msg || "Error");
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err----" + error, process.env.VUE_APP_BASE_API); // for debug
    Toast.fail(error.message || "Error");
    return Promise.reject(error);
  }
);

export default service;
