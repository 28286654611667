import {
  createApp
} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import wechat from './components/wechat.js'
const app = createApp(App);
app.use(store).use(router).mount("#app");
app.config.globalProperties.wxH5Share = wechat
import {
  Button,
  NavBar,
  Field,
  CellGroup,
  CountDown,
  Toast,
  Swipe,
  SwipeItem,
} from "vant";
app
  .use(SwipeItem)
  .use(Swipe)
  .use(Toast)
  .use(Button)
  .use(NavBar)
  .use(Field)
  .use(CellGroup)
  .use(CountDown);