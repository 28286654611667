<template>
	<div class="home">
		<!-- <button @click="jump">回到小程序我的页面</button> -->
	</div>
</template>
<script>
import { navigateToCheckStand } from "@/components/wechat";
	export default {
		name: "Home",
		data() {
			return {
				orderId: '' //订单号
			}
		},
		created() {},
		mounted() {
			let value = window.location.search || 'order_no=11111'
			this.orderId = value.substring(value.indexOf('=') + 1, value.length)
			navigateToCheckStand(this.orderId)
			// this.jump() //跳回小程序
		},

		methods: {
			jump() {
				console.log('wx',wx)
				 console.log(this.orderId)
				uni.getEnv(function(res) {
					console.log('成功')
					console.log(res,'getenv')
				})
				uni.postMessage({
					data: {
						orderId: this.orderId
					}
				})
				uni.redirectTo({
					url: `/pages/checkstand/index?orderId=${this.orderId}` ,// 小程序中页面的路径
					// url: '/pages/my/home' // 小程序中页面的路径
				})
				// console.log('wx',uni)
				// console.log(this.orderId)
				// wx.miniProgram.navigateTo({
				//         url: '/pages/checkstand/index?orderId=' + this.orderId
				//       });
				
				
				// console.log(this.orderId)
				// document.addEventListener('UniAppJSBridgeReady', function() {
				// 	console.log('成功')
				// 	uni.getEnv(function(res) {
				// 		uni.postMessage({
				// 			data: {
				// 				orderId: this.orderId
				// 			}
				// 		})
				// 		uni.navigateTo({
				// 			url: '/pages/checkstand/index' // 小程序中页面的路径
				// 		})
				// 	})
				// })
			}
		}
	}
</script>
