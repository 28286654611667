//引入js-sdk
// var wx = require('weixin-js-sdk');
import { getShareSign } from "@/api/api_library/trial";
export default {
    getConfig(infoTitle, infoDesc, infoLink, infoImg) {  //自定义分享所需要的参数
        // getSignature({//从后台获取签名等内容，其中getSignature为本人自己封装请求，可以按需自己修改。)}
		getShareSign(infoLink)
            .then((res) => {
				debugger
                wx.config({
                    debug: false,  //测试时候用true 能看见wx.config的状态是否是config:ok
                    appId: res.result.appId, // 必填，公众号的唯一标识（公众号的APPid）
                    timestamp: res.result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
                    signature: res.result.signature, // 必填，签名
                    jsApiList: [
                        'onMenuShareTimeline', // 分享给好友
                        'onMenuShareAppMessage', // 分享到朋友圈
                        'updateAppMessageShareData', // 分享给好友1.4
                        'updateTimelineShareData' // 分享到朋友圈1.4
                    ], // 必填，需要使用的JS接口列表
                    openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
                });
                wx.ready(function() {
                    var shareData = {
                        title: infoTitle, // 分享标题
                        desc: infoDesc, // 分享描述
                        link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: infoImg, // 分享图标
                        success: function(res) {
                             
                        }
                    };
                    //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
                    wx.updateAppMessageShareData(shareData);
                    //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
                    wx.updateTimelineShareData(shareData);
                })
            })
			.catch();
    }
}

const ua = navigator.userAgent.toLowerCase()

// NOTE 判断是否微信环境
export const isWeiXinBrowser = () => {
  const reg = /micromessenger/
  return reg.test(ua)
}

// 判断是否微信小程序环境
export const getEnv = () => {
  let flag = false
  if (isWeiXinBrowser()) {
    wx.miniProgram.getEnv(function (res) {
      flag = res.miniprogram
    })
  }
  return flag
}

export const navigateToCheckStand = (orderId) => {
    // 此处应该添加微信环境判断 但项目确实只在微信里运行 就省略不写了
    wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          wx.miniProgram.redirectTo({
          url: `/pages/checkstand/index?orderId=${orderId}`
        })
        }
      })
  }